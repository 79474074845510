import { api, auth } from './defaultProps'
import jwtDecode from 'jwt-decode'
import { errorToast } from '../helpers/ToastHelper'
import { store } from '../redux/store'
import { setToken, setUser } from '../redux/actions'
import history from './history'

const apiV2 = api()
// const local = api({ local: true, localPort: 4000 })
// const apiV1 = api({ v1: true })

const wrappedRequest = async params => {
  try {
    const response = await apiV2(params)
    return response
  } catch (err) {
    errorToast(err.response.data.message)
    if (err.response.status === 401) {
      store.dispatch(setToken(null))
      store.dispatch(setUser(null))
      history.push('/')
    }

    if (err.response.status === 403) {
      history.push('/')
    }
    throw new Error(err.response.data.message)
  }
}

// LOCAL API
// const localWrappedRequest = async params => {
//   try {
//     const response = await local(params)
//     return response
//   } catch (err) {
//     errorToast(err.response.data.message)
//     if (err.response.status === 401) {
//       store.dispatch(setToken(null))
//       store.dispatch(setUser(null))
//       history.push('/')
//     }

//     if (err.response.status === 403) {
//       history.push('/')
//     }
//     throw new Error(err.response.data.message)
//   }
// }

export const login = async (email, password) => {
  const res = await wrappedRequest({
    url: '/auth/local',
    data: {
      email,
      password
    },
    method: 'POST'
  })

  const token = res.headers.authorization.split(' ').pop()
  const userJwt = jwtDecode(token)

  if (userJwt.auth !== auth.admin) {
    throw new Error(403)
  }
  return token
}

export const getAdminArea = async token => {
  const { data: adminArea } = await wrappedRequest({
    url: '/admin/area/me',
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return adminArea
}

export const getMe = async token => {
  const { data: user } = await wrappedRequest({
    url: '/user/me',
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return user
}

export const getMyAdminArea = async token => {
  const { data: adminArea } = await wrappedRequest({
    url: '/admin/area/me',
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return { adminArea }
}

export const selfUpdateUser = async (token, body) => {
  const { data: admin } = await wrappedRequest({
    url: '/admin/area',
    headers: {
      Authorization: token
    },
    data: {
      name: body.name,
      phone: body.phone
    },
    method: 'PATCH'
  })

  return admin
}

export const getAdmins = async token => {
  const { data: admins } = await wrappedRequest({
    url: '/admin/area',
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return admins
}

export const updateAdmin = async (token, id, body) => {
  const {
    data: { admin, adminArea }
  } = await wrappedRequest({
    url: `/admin/area/${id}`,
    data: {
      name: body.name,
      phone: body.phone,
      area: body.area,
      email: body.email
    },
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })

  return { admin, adminArea }
}

export const createAdmin = async (token, body) => {
  const {
    data: { admin, adminArea }
  } = await wrappedRequest({
    url: '/admin/area',
    data: body,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })

  return { admin, adminArea }
}

export const deleteAdmin = async (token, id) => {
  await wrappedRequest({
    url: `/admin/area/${id}`,
    headers: {
      Authorization: token
    },
    method: 'DELETE'
  })
}

export const getTestingConsultants = async token => {
  const { data: testingConsultants } = await wrappedRequest({
    url: `/admin/testing-consultants`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return testingConsultants
}

export const getConsultantsWhoIndicated = async token => {
  const { data: consultantsIndicated } = await wrappedRequest({
    url: `/admin/user/indications/consultants/indication`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return consultantsIndicated
}

export const getIndicatedConsultants = async token => {
  const { data: consultantsIndicated } = await wrappedRequest({
    url: `/admin/user/indications/consultants/indicated`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return consultantsIndicated
}

export const addNewIndication = async (token, body) => {
  await wrappedRequest({
    url: `/admin/user/consultant/indication/add`,
    method: 'PATCH',
    headers: {
      Authorization: token
    },
    data: body
  })
}

export const getConsultantContactHistory = async (token, id, userType) => {
  const { data: contactHistory } = await wrappedRequest({
    url: `/admin/contact/${userType}/${id}`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return contactHistory
}

export const deleteConsultantContact = async (token, id) => {
  await wrappedRequest({
    url: `/admin/contact/${id}`,
    headers: {
      Authorization: token
    },
    method: 'DELETE'
  })
}

export const addConsultantContact = async (token, body) => {
  await wrappedRequest({
    url: `/admin/contact`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })
}

export const addCancellationReason = async (token, body) => {
  await wrappedRequest({
    url: `/admin/cancellation-reason`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })
}

export const getCancellationReasonHistory = async (token, id) => {
  const { data: historyCancellationReason } = await wrappedRequest({
    url: `/admin/cancellation-reason/${id}`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return historyCancellationReason
}

export const deleteCancellationReason = async (token, id) => {
  await wrappedRequest({
    url: `/admin/cancellation-reason/${id}`,
    headers: {
      Authorization: token
    },
    method: 'DELETE'
  })
}

export const editCancellationReason = async (token, id, body) => {
  await wrappedRequest({
    url: `/admin/cancellation-reason/${id}`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })
}

export const editConsultantContact = async (token, id, body) => {
  await wrappedRequest({
    url: `/admin/contact/${id}`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })
}

export const addLead = async (token, body) => {
  await wrappedRequest({
    url: `/admin/crm/lead`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })
}

export const convertLead = async (token, userId, body) => {
  await wrappedRequest({
    url: `/admin/crm/lead/${userId}`,
    data: body,
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })
}

export const getCRM = async token => {
  const { data: Crm } = await wrappedRequest({
    url: `/admin/crm`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return Crm
}

export const getUserDetailedData = async (token, id, isLead) => {
  const { data: testingConsultant } = await wrappedRequest({
    url: `/admin/crm/details/${id}`,
    headers: {
      Authorization: token
    },
    params: {
      isLead: isLead
    },
    method: 'GET'
  })

  return testingConsultant
}

export const getSubsCount = async token => {
  const { data: subsCount } = await wrappedRequest({
    url: `/admin/crm/count-subs`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subsCount
}

export const getTopConsultatsWithSubscribeChance = async token => {
  const { data: topConsultants } = await wrappedRequest({
    url: `/admin/crm/top-chances`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return topConsultants
}

export const extendUserSubscription = async (token, userId) => {
  await wrappedRequest({
    url: `/subscription/freetrial/admin/user/${userId}/extend`,
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })
}

export const getSubscribers = async token => {
  const { data: subscribers } = await wrappedRequest({
    url: `/admin/crm/subscribers`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscribers
}

export const getSubscriptions = async token => {
  const { data: subscriptions } = await wrappedRequest({
    url: `/subscription/admin/list`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscriptions
}

export const getLastCreatedSubscriptions = async token => {
  const { data: subscriptions } = await wrappedRequest({
    url: `/subscription/admin/list/active`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscriptions
}

export const getLastCancelledSubscriptions = async token => {
  const { data: subscriptions } = await wrappedRequest({
    url: `/subscription/admin/list/cancelled/last`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscriptions
}

export const getUserSubscriptions = async (token, userId) => {
  const { data: subscriptions } = await wrappedRequest({
    url: `/subscription/admin/list/user/${userId}`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscriptions
}

export const getSubscriptionsCancelled = async token => {
  const { data: subscriptions } = await wrappedRequest({
    url: `/subscription/admin/list/cancelled`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return subscriptions
}

export const createUserSubscription = async (token, userId, body) => {
  await wrappedRequest({
    url: `/subscription/admin/user/${userId}`,
    headers: {
      Authorization: token
    },
    method: 'POST',
    data: body
  })
}

export const extendUserSubscriptionAdmin = async (token, userId, body) => {
  await wrappedRequest({
    url: `/subscription/admin/user/${userId}/extend`,
    method: 'PATCH',
    headers: {
      Authorization: token
    },
    data: body
  })
}

export const scheduleCancelSubscription = async (token, userId) => {
  await wrappedRequest({
    url: `/subscription/admin/user/${userId}/cancel/schedule`,
    method: 'PATCH',
    headers: {
      Authorization: token
    }
  })
}

export const cancelSubscription = async (token, userId) => {
  await wrappedRequest({
    url: `/subscription/admin/user/${userId}/cancel`,
    method: 'PATCH',
    headers: {
      Authorization: token
    }
  })
}

export const editConsultantEmail = async (token, userId, body) => {
  await wrappedRequest({
    url: `/admin/user/consultant/email/${userId}`,
    method: 'PATCH',
    headers: {
      Authorization: token
    },
    data: body
  })
}

export const getBlockedUsersFromReceivingEmail = async token => {
  const { data: blockedUsers } = await wrappedRequest({
    url: `/user/email/blocked/list`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return blockedUsers
}

export const releaseUserToReceiveEmail = async (token, email) => {
  await wrappedRequest({
    url: `/user/email/release?email=${email}`,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })
}

export const searchClients = async (token, search) => {
  const { data: clients } = await wrappedRequest({
    url: `/admin/user/client/search?search=${search}`,
    method: 'GET',
    headers: {
      Authorization: token
    }
  })

  return clients
}

export const getDeletedClientsHistory = async token => {
  const { data: clients } = await wrappedRequest({
    url: `/admin/user/client/historyDeleted`,
    method: 'GET',
    headers: {
      Authorization: token
    }
  })

  return clients
}

export const getConsultantsChangeHistory = async token => {
  const { data: clients } = await wrappedRequest({
    url: `/admin/user/client/historyChangeConsultants`,
    method: 'GET',
    headers: {
      Authorization: token
    }
  })

  return clients
}

export const getConsultants = async token => {
  const { data: consultants } = await wrappedRequest({
    url: `/admin/user/consultant`,
    method: 'GET',
    headers: {
      Authorization: token
    }
  })

  return consultants
}

export const changeConsultant = async (token, userId, body) => {
  await wrappedRequest({
    url: `/admin/user/client/changeConsultant/${userId}`,
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'image/png'
    },
    data: body
  })
}

export const permanentlyDeleteClient = async (token, userId, body) => {
  await wrappedRequest({
    url: `/admin/user/client/permanentlyDelete/${userId}`,
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'image/png'
    },
    data: body
  })
}

export const restoreClient = async (token, userId) => {
  await wrappedRequest({
    url: `/admin/user/client/restore/${userId}`,
    method: 'PATCH',
    headers: {
      Authorization: token
    }
  })
}

export const generatePasswordRecoveryClientLink = async (token, body) => {
  return await wrappedRequest({
    url: `/auth/reset/client/generate-link`,
    method: 'POST',
    headers: {
      Authorization: token
    },
    data: body
  })
}

export const getDocumentImage = async (token, idDocument) => {
  const { data: consultants } = await wrappedRequest({
    url: `/admin/user/client/history/request-document/${idDocument}`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return consultants
}

export const getCoupons = async token => {
  const { data: coupons } = await wrappedRequest({
    url: `/coupon`,
    headers: {
      Authorization: token
    },
    method: 'GET'
  })

  return coupons
}

export const createNewCoupon = async (token, coupon) => {
  await wrappedRequest({
    url: `/coupon`,
    data: coupon,
    headers: {
      Authorization: token
    },
    method: 'POST'
  })
}

export const deleteCoupon = async (token, coupon) => {
  await wrappedRequest({
    url: `/coupon`,
    data: coupon,
    headers: {
      Authorization: token
    },
    method: 'DELETE'
  })
}

export const editCoupon = async (token, coupon) => {
  await wrappedRequest({
    url: `/coupon`,
    data: coupon,
    headers: {
      Authorization: token
    },
    method: 'PATCH'
  })
}
