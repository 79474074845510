import React, { useState, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { convertBlobToBase64 } from '../helpers/ImageHelpers'
import SimpleActionPopup from '../components/SimpleActionPopup'
import SelectConsultantPopup from '../components/SelectConsultantPopup'
import { permanentlyDeleteClient, restoreClient, generatePasswordRecoveryClientLink } from '../services/utils'
import { errorToast, successToast } from '../helpers/ToastHelper'
import CopyIcon from '../assets/images/copy.svg'
import '../styles/containers/EditClient.css'

const EditClient = props => {
  const location = useLocation()

  const navigate = useNavigate()

  let [user, setUser] = useState(location.state.user)

  /* Change popup consultant states */
  let [showPopup, setShowPopup] = useState(false)

  let [
    isRecovyPasswordClientLinkPopupShowing,
    setIsRecovyPasswordClientLinkPopupShowing
  ] = useState(false)
  let [
    isPermanenltyDeleteClientPopupShowing,
    setIsPermanentlyDeleteClientPopupShowing
  ] = useState(false)
  let [
    isPermanentlyDeleteClientConfirmationPopupShowing,
    setIsPermanentlyDeleteClientConfirmationPopupShowing
  ] = useState(false)
  let [isRestoreClientPopupShowing, setIsRestoreClientPopupShowing] = useState(
    false
  )

  let [popupLoading, setPopupLoading] = useState(true)

  let [
    permanentlyDeleteConfirmationFile,
    setPermanentlyDeleteConfirmationFile
  ] = useState(null)

  let [
    recoveryPasswordLink,
    setRecoveryPasswordLink
  ] = useState(null)

  useEffect(() => {
    if (isRecovyPasswordClientLinkPopupShowing){
      handleGeneretePasswordLinkClient()
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isRecovyPasswordClientLinkPopupShowing])

  const permanentlyDeleteClientPopupContent = () => {
    return (
      <div className='update-consultant-confirmation'>
        <h3>Cliente</h3>
        <p>{user.name}</p>
        <h3>Consultora Atual</h3>
        <p>{user.consultantName}</p>

        <br />
        <h3>
          Anexe uma imagem com a solicitação de EXCLUSÃO PERMANENTE da cliente:
        </h3>
        <div className='change-consultant-label-container'>
          <label
            htmlFor='delete-consultant-file'
            className='change-consultant-file-label'
          >
            ANEXAR SOLICITAÇÃO
          </label>
          <p>
            {permanentlyDeleteConfirmationFile &&
              permanentlyDeleteConfirmationFile.name}
          </p>
        </div>
        <input
          id='delete-consultant-file'
          type='file'
          onChange={e =>
            e.target.files.length > 0
              ? setPermanentlyDeleteConfirmationFile(e.target.files[0])
              : setPermanentlyDeleteConfirmationFile(null)
          }
        />
      </div>
    )
  }

  const handlePermanentlyDeleteConsultant = async () => {
    if (!permanentlyDeleteConfirmationFile) {
      return errorToast(
        'Selecione a imagem com a solicitação de exclusão permanente da consultora!'
      )
    }

    setPopupLoading(true)

    try {
      const base64File = await convertBlobToBase64(
        permanentlyDeleteConfirmationFile
      )

      const body = {
        image: base64File
      }

      await permanentlyDeleteClient(props.token, user.id, body)
      setPermanentlyDeleteConfirmationFile(null)

      navigate('/sucesso-do-cliente/clientes')
      successToast('Usuário deletado com sucesso!')
    } catch (err) {
      console.error(err)
    } finally {
      setIsPermanentlyDeleteClientPopupShowing(false)
      setPopupLoading(false)
    }
  }

  const handleGeneretePasswordLinkClient = async () => {
    setPopupLoading(true)
    try {
      const body = {
        email: user.email
      }

      const link = await generatePasswordRecoveryClientLink(props.token, body)
      
      setRecoveryPasswordLink(link.data)
      successToast('Link de recuperação de senha gerado com sucesso!')
    } catch (err) {
      console.error(err)
    } finally {
      setPopupLoading(false)
    }
  }
  
  const permanentlyDeleteClientConfirmationPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Tem <strong>certeza</strong> que deseja{' '}
          <strong>excluir permanentemente</strong> essa cliente? Essa ação{' '}
          <strong>não poderá ser desfeita</strong> e a cliente{' '}
          <strong>não conseguirá recuperar seus dados</strong>.
        </p>
      </div>
    )
  }

  const recoveryLinkPopupContent = () => {
    return (
      <div className='permanently-delete-client-confirmation-popup'>
        <p>
          Este é o link de recuperação de senha do cliente <strong>{user.name}</strong>:
        </p>
        <div 
          className='link-password-input'
          onClick={() => {
            navigator.clipboard.writeText(recoveryPasswordLink)
            successToast('Link de recuperação copiado com sucesso!')
          }}
        >
          <p>{recoveryPasswordLink && recoveryPasswordLink.substring(0, 60) + '...'}</p>
          <button style={{ width: '25px', opacity: '0.8' }}>
            <img src={CopyIcon} alt='copy' />
          </button>
        </div>
      </div>
    )
  }

  const restoreClientPopupContent = () => {
    return (
      <div className='restore-client-popup'>
        <p>Deseja realmente restaurar essa cliente?</p>
      </div>
    )
  }

  const handleRestoreClient = async () => {
    setPopupLoading(true)

    try {
      await restoreClient(props.token, user.id)

      setUser({
        ...user,
        isDeleted: 0
      })

      successToast('Cliente restaurado com sucesso!')
    } catch (err) {
      console.error(err)
    } finally {
      setIsRestoreClientPopupShowing(false)
      setPopupLoading(false)
    }
  }

  useEffect(() => {
    setPopupLoading(false)
  }, [user])

  return (
    <div className='content-screen-container general-screen-container'>
      <div className='main-screen-header-container'>
        <h2>Sucesso do Cliente - Editar Cliente</h2>
      </div>
      <h4>{user.name}</h4>
      <div className='edit-client-screen-section'>
        <h5>Consultora</h5>
        <div className='edit-client-screen-consultant-name'>
          <p>{user.consultantName}</p>
          <button
            onClick={() => setShowPopup(true)}
            className='edit-client-screen-button'
          >
            TROCAR
          </button>
        </div>
      </div>
      {user.isDeleted ? (
        <div className='edit-client-screen-section'>
          <h5>Cliente foi apagada pela consultora</h5>
          <button
            onClick={() => setIsRestoreClientPopupShowing(true)}
            className='edit-client-screen-restore edit-client-screen-button'
          >
            RESTAURAR CLIENTE
          </button>
        </div>
      ) : null}
      {!user.isDeleted && (
        <div className='edit-client-screen-section'>
          <h5>Gerar Link de Recuperação de senha</h5>
          <p className='edit-client-screen-delete-warning'>
            Você pode gerar um link de recuperação de senha para a cliente manualmente. Mas cuidado! certifique-se de que 
            é a propria cliente ou consultora esta requisitando este link. Com este link qualquer um consegue alterar a senha da cliente.
          </p>
          <button
            onClick={() => setIsRecovyPasswordClientLinkPopupShowing(true)}
            className='edit-client-screen-password edit-client-screen-button'
          >
            GERAR LINK DE RECUPERAÇÃO
          </button>
        </div>
      )}
      <div className='edit-client-screen-section'>
        <h5>Apagar cliente permanentemente</h5>
        <p className='edit-client-screen-delete-warning'>
          Você pode apagar a cliente permanentemente caso a consultora tenha
          solicitado. Mas cuidado! Essa ação é <strong>IRREVERSÍVEL</strong>!
          Todos os dados da cliente serão perdidos!
        </p>
        <button
          onClick={() => setIsPermanentlyDeleteClientPopupShowing(true)}
          className='edit-client-screen-delete edit-client-screen-button'
        >
          APAGAR CLIENTE PARA SEMPRE
        </button>
      </div>
      {showPopup && (
        <SelectConsultantPopup
          type={'editClient'}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          setUsers={setUser}
        />
      )}
      {isPermanenltyDeleteClientPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={permanentlyDeleteClientPopupContent()}
          onClosePopup={() => setIsPermanentlyDeleteClientPopupShowing(false)}
          title='Apagar Cliente Permanentemente'
          secondActionButtonStyle={
            permanentlyDeleteConfirmationFile
              ? {
                  width: 280,
                  background: 'var(--linear-danger-1)',
                  color: 'white'
                }
              : {
                  width: 280,
                  background: 'var(--base-4)',
                  color: 'white',
                  cursor: 'initial'
                }
          }
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='EXCLUIR PARA SEMPRE'
          onPressSecondActionButton={() => {
            if (!permanentlyDeleteConfirmationFile) {
              return errorToast(
                'Selecione a imagem com a solicitação de exclusão permanente da consultora!'
              )
            }
            setIsPermanentlyDeleteClientPopupShowing(false)
            setIsPermanentlyDeleteClientConfirmationPopupShowing(true)
          }}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setIsPermanentlyDeleteClientPopupShowing(false)
          }
        />
      )}
      {isPermanentlyDeleteClientConfirmationPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={permanentlyDeleteClientConfirmationPopupContent()}
          onClosePopup={() =>
            setIsPermanentlyDeleteClientConfirmationPopupShowing(false)
          }
          title='Apagar Cliente Permanentemente'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='EXCLUIR PARA SEMPRE'
          onPressSecondActionButton={handlePermanentlyDeleteConsultant}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setIsPermanentlyDeleteClientConfirmationPopupShowing(false)
          }
        />
      )}
      {isRestoreClientPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={restoreClientPopupContent()}
          onClosePopup={() => setIsRestoreClientPopupShowing(false)}
          title='Restaurar Cliente'
          secondActionButtonText='RESTAURAR'
          onPressFirstActionButton={() => {
            setIsRestoreClientPopupShowing(false)
          }}
          firstActionButtonText='CANCELAR'
          onPressSecondActionButton={handleRestoreClient}
          firstActionButtonStyle={{ width: 240 }}
          secondActionButtonStyle={{ width: 240 }}
        />
      )}
      {isRecovyPasswordClientLinkPopupShowing && (
        <SimpleActionPopup
          popupLoading={popupLoading}
          content={recoveryLinkPopupContent()}
          onClosePopup={() =>
            setIsRecovyPasswordClientLinkPopupShowing(false)
          }
          title='Link de recuperação de senha'
          secondActionButtonStyle={{
            width: 280,
            background: 'var(--linear-danger-1)',
            color: 'white'
          }}
          firstActionButtonStyle={{
            width: 280,
            background: 'var(--linear-success-1)',
            color: 'black'
          }}
          secondActionButtonText='COPIAR LINK'
          onPressSecondActionButton={() => {
            navigator.clipboard.writeText(recoveryPasswordLink)
            successToast('Link de recuperação copiado com sucesso!')
          }}
          firstActionButtonText='CANCELAR'
          onPressFirstActionButton={() =>
            setIsRecovyPasswordClientLinkPopupShowing(false)
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  const { user } = state
  return {
    user: user.user,
    token: user.token,
    adminArea: user.adminArea
  }
}

export default connect(mapStateToProps)(EditClient)
